<template>
  <div class="page-content-box" style="background:#f5f5f5">
    <div class="info-banner" style="height:700px">
      <head-main class="head-white no-fixed"></head-main>

      <el-carousel class="custon-carousel carousel-index" :interval="5000" arrow="nerver" height="700px">
        <el-carousel-item
          :style="{
            backgroundImage: 'url(' + projectInfo.projectImg + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }"
        >
        </el-carousel-item>
      </el-carousel>

      <div class="custon-bread ">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>县市地图</el-breadcrumb-item>
          <el-breadcrumb-item>{{ projectInfo.provinceCityCounty }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ projectInfo.projectName }}</el-breadcrumb-item>
        </el-breadcrumb> -->
      </div>
      <div class="city-introduce">
        <!-- <h5 class="city-tits">点击弹出全景框<i class="qj-i"></i></h5> -->
      </div>
    </div>
<!-- {{projectVideo}} -->
    <div class="city-contnt-one pd-0">
      <div class="city-con-width">
        <div class="custon-line">
          <span class="line-tit"> 项目<i class="tit-red">介绍</i> </span>
        </div>
        <div class="sys-introduce-box">
          <div class="intro-img">
            <el-carousel class="custon-carousel" :interval="5000" arrow="nerver" height="380px">
              <!-- v-for="item in infoList" -->
              <!-- :key="item.index" -->
              <el-carousel-item
                v-for="img in projectInfo.swiperImgs"
                :key="img"
                :style="{
                  backgroundImage: 'url(' + img + ')',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover'
                }"
              >
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="intro-con">
            <div class="intro-info-box">
              <div class="intro-l">
                <p>项目名称：{{ projectInfo.projectName }}</p>
                <p>非遗类型：{{ projectInfo.fintangibleType }}</p>
                <!-- <p>非遗级别：{{ projectInfo.intangibleLevel }}</p> -->
                <p>所在地区：{{ projectInfo.provinceCityCounty }}</p>
                <p>申报单位：{{ projectInfo.applicationUnit }}</p>
              </div>
              <div class="intro-r">
                <span
                  :style="{
                    backgroundImage: 'url(' + wecatImg + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                  }"
                ></span>
                <p>手机扫码浏览</p>
              </div>
            </div>
            <div class="intro-info-p">
              <p v-html="projectInfo.webDetailContent"></p>
            </div>
            <!-- <div class="btn-box">
              <el-button class="more-btn">查看全部</el-button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="city-contnt-one" v-if="projectNewsList.length > 0">
      <div class="city-con-width">
        <div class="custon-line">
          <span class="line-tit"> 项目<i class="tit-red">资讯</i> </span>
        </div>
        <div class="sys-zx-list">
          <ul class="sys-zx-ul">
            <li v-for="item in projectNewsList" :key="item.id">
              <div class="dynamic-box">
                <div class="dynamic-time">
                  <span class="time-year">{{ item.createTime.split('-')[0] }}-{{ item.createTime.split('-')[1] }}</span>
                  <span class="time-date">{{ item.createTime.split('-')[1].split(' ')[0] }}</span>
                </div>
                <div class="dynamic-con">
                  <h5 @click="toContent(item)">{{ item.title }}</h5>
                  <p>{{ item.summary }}</p>
                </div>
              </div>
            </li>
          </ul>
          <div class="btn-box">
            <el-button class="more-btn">查看全部</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 
    <div class="city-contnt-two two-bg">
      <div class="city-con-width">
        <div class="person-tit">
          <span class="per-tit"> 项目<i class="tit-red">传承人</i> </span>
        </div>

        <div class="person-tab">
          <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane v-for="item in inheritorList" :key="item.id" :label="item.trueName"> </el-tab-pane>
          </el-tabs>
          <el-carousel
            class="custon-carousel"
            ref="carousel"
            :interval="5000"
            arrow="nerver"
            height="600px"
            @change="handleCarouselChange"
          >
            <el-carousel-item v-for="item in inheritorList" :key="item.id">
              <div class="city-system person-tab-con">
                <div class="img-info-box">
                  <div class="border-bg">
                    <span
                      class="system-img"
                      :style="{
                        backgroundImage: 'url(' + item.webImg + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%'
                      }"
                    ></span>
                    <div class="img-info-txt">
                      <p>项目名称：{{ item.fintangibleDir }}</p>
                      <p>非遗类型：{{ item.fintangibleType }}</p>-
                       <p>非遗级别：{{ item.inheritorLevel }}</p> 
                     <p>所在地区：{{ item.provinceCityCounty }}</p>
                      <el-button class="view-more" @click="toContent(item)">查看详情</el-button>
                    </div>
                  </div>
                </div>
                <div class="system-tit">
                  <span class="tit-span">
                    <i class="tit-i1"></i>
                    <span>项目传承人---{{ item.trueName }}</span>
                    <i class="tit-i2"></i>
                  </span>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div> 
-->

    <!-- -->
    <div class="city-contnt-one  pd-0">
      <div class="city-con-width">
        <div class="custon-line">
          <span class="line-tit"> 项目<i class="tit-red">传承人</i> </span>
        </div>

        <div class="person-item">
          <el-carousel class="custon-carousel" :interval="10000" arrow="never" height="800px">
            <el-carousel-item v-for="i in Math.ceil(inheritorList.length / 6)" :key="i">
              <ul class="person-ul">
                <template v-for="j in 6">
                  <li
                    style="height: 310px"
                    v-if="inheritorList[(i - 1) * 6 + (j - 1)]"
                    :key="j"
                    @click="toContent(inheritorList[(i - 1) * 6 + (j - 1)])"
                  >
                    <span
                      class="person-img"
                      :style="{
                        backgroundImage: 'url(' + (inheritorList[(i - 1) * 6 + (j - 1)].webImg || empty) + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%'
                      }"
                    ></span>
                    <h5 class="person-name">
                      <i class="name-i"></i>{{ inheritorList[(i - 1) * 6 + (j - 1)].trueName }}
                    </h5>
                    <!-- <p class="person-po">非遗项目：{{ relaInheritorList[(i - 1) * 6 + (j - 1)].fintangibleDir }}</p>
					<p class="person-po">非遗类型：{{ relaInheritorList[(i - 1) * 6 + (j - 1)].fintangibleType }}</p>
					<p class="person-po">非遗级别：{{ relaInheritorList[(i - 1) * 6 + (j - 1)].inheritorLevel }}</p>
					<p class="person-po">所在地区：{{ relaInheritorList[(i - 1) * 6 + (j - 1)].provinceCityCounty }}</p> -->
                  </li>
                </template>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="city-contnt-one  pd-0">
      <div class="city-con-width">
        <div class="custon-line">
          <span class="line-tit"> 推荐<i class="tit-red">传承人</i> </span>
        </div>

        <div class="person-item">
          <el-carousel class="custon-carousel" :interval="10000" arrow="never" height="800px">
            <el-carousel-item v-for="i in Math.ceil(relaInheritorList.length / 6)" :key="i">
              <ul class="person-ul">
                <template v-for="j in 6">
                  <li
                    v-if="relaInheritorList[(i - 1) * 6 + (j - 1)]"
                    :key="j"
                    @click="toContent(relaInheritorList[(i - 1) * 6 + (j - 1)])"
                  >
                    <span
                      class="person-img"
                      :style="{
                        backgroundImage: 'url(' + (relaInheritorList[(i - 1) * 6 + (j - 1)].contentImg || empty) + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%'
                      }"
                    ></span>
                    <h5 class="person-name">
                      <i class="name-i"></i>{{ relaInheritorList[(i - 1) * 6 + (j - 1)].contentName }}
                    </h5>
                    <p class="person-po">非遗项目：{{ relaInheritorList[(i - 1) * 6 + (j - 1)].extInfo }}</p>
                  </li>
                </template>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="city-contnt-one">
      <div class="city-con-width">
        <div class="custon-line">
          <span class="line-tit"> 推荐<i class="tit-red">项目</i> </span>
        </div>

        <div class="person-item person-items">
          <el-carousel class="custon-carousel" :interval="10000" arrow="never" height="620px">
            <el-carousel-item v-for="i in Math.ceil(relaProjectList.length / 6)" :key="i">
              <ul class="imgList">
                <template v-for="j in 6">
                  <li
                    v-if="relaProjectList[(i - 1) * 6 + (j - 1)]"
                    :key="j"
                    :style="{
                      backgroundImage: 'url(' + (relaProjectList[(i - 1) * 6 + (j - 1)].contentImg || empty) + ')',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: '100% 100%'
                    }"
                    @click="toContent(relaProjectList[(i - 1) * 6 + (j - 1)])"
                  >
                    <div class="fu-div">
                      {{ relaProjectList[(i - 1) * 6 + (j - 1)].contentName }}
                    </div>
                  </li>
                </template>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <botto-com class="bottom-white no-fixed"></botto-com>
	<pop-video :video="projectVideo" ref="popVideo"></pop-video>
  </div>
</template>
<script>
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'
import popVideo from '../../components/popVideo.vue'
export default {
  components: {
    HeadMain,
    BottoCom,
	popVideo
  },
  data() {
    return {
      empty: require('../../assets/images/empty-pic.png'),
      projectInfo: {
        applicationUnit: '',
        createTime: '',
        fintangibleType: '',
        intangibleLevel: '',
        provinceCityCounty: '',
        projectImg: '',
        projectName: '',
        swiperImgs: [],
        webDetailContent: ''
		
      },
	  projectVideo:'',
      imgSrc: require('../../assets/images/img1.jpg'),

      relaProjectList: [],

      relaInheritorList: [],
      //new
      inheritorList: [],
      projectNewsList: [
        // {
        //   year: '2020-09',
        //   date: 30,
        //   tit: '迎双节，福建省非遗博览苑首次开放夜间参观',
        //   con:
        //     '金秋九月，丹桂飘香。为欢度国庆与中秋佳节、迎接“第44届世界遗产大会”同时庆祝我国抗疫取得阶段性胜利，由福建滑稽的是封建士大夫'
        // },
        // {
        //   year: '2020-09',
        //   date: 30,
        //   tit: '迎双节，福建省非遗博览苑首次开放夜间参观',
        //   con:
        //     '金秋九月，丹桂飘香。为欢度国庆与中秋佳节、迎接“第44届世界遗产大会”同时庆祝我国抗疫取得阶段性胜利，由福建滑稽的是封建士大夫'
        // },
        // {
        //   year: '2020-09',
        //   date: 30,
        //   tit: '迎双节，福建省非遗博览苑首次开放夜间参观',
        //   con:
        //     '金秋九月，丹桂飘香。为欢度国庆与中秋佳节、迎接“第44届世界遗产大会”同时庆祝我国抗疫取得阶段性胜利，由福建滑稽的是封建士大夫'
        // },
        // {
        //   year: '2020-09',
        //   date: 30,
        //   tit: '迎双节，福建省非遗博览苑首次开放夜间参观',
        //   con:
        //     '金秋九月，丹桂飘香。为欢度国庆与中秋佳节、迎接“第44届世界遗产大会”同时庆祝我国抗疫取得阶段性胜利，由福建滑稽的是封建士大夫'
        // }
      ],
      // infoImg:require('../../assets/images/info-img.jpg'),
      wecatImg: require('../../assets/images/wet-phone.png'),
      activeTab: 0,
      infoList: [
        { img: require('../../assets/images/info-img.jpg') },
        { img: require('../../assets/images/info-img.jpg') },
        { img: require('../../assets/images/info-img.jpg') }
      ]
    }
  },

  mounted() {
    this.getProjectData()
    document.getElementsByTagName('body')[0].className = 'page-body'
  },
  methods: {
    getProjectData() {
      this.axios
        .get('/api/portal/cont/pub-detail', {
          params: {
            id: this.$route.query.id
            // id: '1326505727581085697'
          }
        })
        .then(res => {
          let data = res.data
          console.log(data)
          let porjectData = data.porjectData
          this.projectInfo = {
            applicationUnit: porjectData.applicationUnit,
            createTime: porjectData.createTime,
            fintangibleType: porjectData.fintangibleType,
            intangibleLevel: porjectData.intangibleLevel,
            provinceCityCounty: porjectData.provinceCityCounty,
            projectImg: porjectData.projectImg,
            projectName: porjectData.projectName,
            swiperImgs: porjectData.swiperImgs.split(','),
            webDetailContent: porjectData.webDetailContent,
			
          }
		  console.log(porjectData.projectVideo)
		  if(porjectData.projectVideo){
			  this.projectVideo=porjectData.projectVideo;
			  this.$refs.popVideo.open();
		  }else{
			  this.projectVideo="";
		  }

          this.projectNewsList = data.projectArticles ? data.projectArticles.records : []
          this.relaInheritorList = data.relaInheritorList || []
          this.relaProjectList = data.relaProjectList || []
          this.inheritorList = data.inheritors || []
        })
    },
    toContent(item) {
      let { contentUrl, contentId, inheritorId, id } = item
      document.body.scrollIntoView()
      this.$router.push({ path: contentUrl, query: { id, contentId: contentId || inheritorId } })
      if (this.$route.path === contentUrl) {
        this.projectInfo = {
          applicationUnit: '',
          createTime: '',
          fintangibleType: '',
          intangibleLevel: '',
          provinceCityCounty: '',
          projectImg: '',
          projectName: '',
          swiperImgs: [],
          webDetailContent: ''
        }

        this.projectNewsList = []
        this.relaInheritorList = []
        this.relaProjectList = []
        this.inheritorList = []
        this.getProjectData()
      }
    },
    handleTabClick(tab) {
      this.$refs.carousel.setActiveItem(tab.index.toString())
    },
    handleCarouselChange(index) {
      this.activeTab = index.toString()
    }
  },
  beforeDestroy() {
    document.body.removeAttribute('class', 'page-body')
  }
}
</script>
<style lang="scss" scoped></style>
