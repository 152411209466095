var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content-box",staticStyle:{"background":"#f5f5f5"}},[_c('div',{staticClass:"info-banner",staticStyle:{"height":"700px"}},[_c('head-main',{staticClass:"head-white no-fixed"}),_c('el-carousel',{staticClass:"custon-carousel carousel-index",attrs:{"interval":5000,"arrow":"nerver","height":"700px"}},[_c('el-carousel-item',{style:({
            backgroundImage: 'url(' + _vm.projectInfo.projectImg + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          })})],1),_c('div',{staticClass:"custon-bread "}),_c('div',{staticClass:"city-introduce"})],1),_c('div',{staticClass:"city-contnt-one pd-0"},[_c('div',{staticClass:"city-con-width"},[_vm._m(0),_c('div',{staticClass:"sys-introduce-box"},[_c('div',{staticClass:"intro-img"},[_c('el-carousel',{staticClass:"custon-carousel",attrs:{"interval":5000,"arrow":"nerver","height":"380px"}},_vm._l((_vm.projectInfo.swiperImgs),function(img){return _c('el-carousel-item',{key:img,style:({
                  backgroundImage: 'url(' + img + ')',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover'
                })})}),1)],1),_c('div',{staticClass:"intro-con"},[_c('div',{staticClass:"intro-info-box"},[_c('div',{staticClass:"intro-l"},[_c('p',[_vm._v("项目名称："+_vm._s(_vm.projectInfo.projectName))]),_c('p',[_vm._v("非遗类型："+_vm._s(_vm.projectInfo.fintangibleType))]),_c('p',[_vm._v("所在地区："+_vm._s(_vm.projectInfo.provinceCityCounty))]),_c('p',[_vm._v("申报单位："+_vm._s(_vm.projectInfo.applicationUnit))])]),_c('div',{staticClass:"intro-r"},[_c('span',{style:({
                    backgroundImage: 'url(' + _vm.wecatImg + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                  })}),_c('p',[_vm._v("手机扫码浏览")])])]),_c('div',{staticClass:"intro-info-p"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.projectInfo.webDetailContent)}})])])])])]),(_vm.projectNewsList.length > 0)?_c('div',{staticClass:"city-contnt-one"},[_c('div',{staticClass:"city-con-width"},[_vm._m(1),_c('div',{staticClass:"sys-zx-list"},[_c('ul',{staticClass:"sys-zx-ul"},_vm._l((_vm.projectNewsList),function(item){return _c('li',{key:item.id},[_c('div',{staticClass:"dynamic-box"},[_c('div',{staticClass:"dynamic-time"},[_c('span',{staticClass:"time-year"},[_vm._v(_vm._s(item.createTime.split('-')[0])+"-"+_vm._s(item.createTime.split('-')[1]))]),_c('span',{staticClass:"time-date"},[_vm._v(_vm._s(item.createTime.split('-')[1].split(' ')[0]))])]),_c('div',{staticClass:"dynamic-con"},[_c('h5',{on:{"click":function($event){return _vm.toContent(item)}}},[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.summary))])])])])}),0),_c('div',{staticClass:"btn-box"},[_c('el-button',{staticClass:"more-btn"},[_vm._v("查看全部")])],1)])])]):_vm._e(),_c('div',{staticClass:"city-contnt-one  pd-0"},[_c('div',{staticClass:"city-con-width"},[_vm._m(2),_c('div',{staticClass:"person-item"},[_c('el-carousel',{staticClass:"custon-carousel",attrs:{"interval":10000,"arrow":"never","height":"800px"}},_vm._l((Math.ceil(_vm.inheritorList.length / 6)),function(i){return _c('el-carousel-item',{key:i},[_c('ul',{staticClass:"person-ul"},[_vm._l((6),function(j){return [(_vm.inheritorList[(i - 1) * 6 + (j - 1)])?_c('li',{key:j,staticStyle:{"height":"310px"},on:{"click":function($event){_vm.toContent(_vm.inheritorList[(i - 1) * 6 + (j - 1)])}}},[_c('span',{staticClass:"person-img",style:({
                        backgroundImage: 'url(' + (_vm.inheritorList[(i - 1) * 6 + (j - 1)].webImg || _vm.empty) + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%'
                      })}),_c('h5',{staticClass:"person-name"},[_c('i',{staticClass:"name-i"}),_vm._v(_vm._s(_vm.inheritorList[(i - 1) * 6 + (j - 1)].trueName)+" ")])]):_vm._e()]})],2)])}),1)],1)])]),_c('div',{staticClass:"city-contnt-one  pd-0"},[_c('div',{staticClass:"city-con-width"},[_vm._m(3),_c('div',{staticClass:"person-item"},[_c('el-carousel',{staticClass:"custon-carousel",attrs:{"interval":10000,"arrow":"never","height":"800px"}},_vm._l((Math.ceil(_vm.relaInheritorList.length / 6)),function(i){return _c('el-carousel-item',{key:i},[_c('ul',{staticClass:"person-ul"},[_vm._l((6),function(j){return [(_vm.relaInheritorList[(i - 1) * 6 + (j - 1)])?_c('li',{key:j,on:{"click":function($event){_vm.toContent(_vm.relaInheritorList[(i - 1) * 6 + (j - 1)])}}},[_c('span',{staticClass:"person-img",style:({
                        backgroundImage: 'url(' + (_vm.relaInheritorList[(i - 1) * 6 + (j - 1)].contentImg || _vm.empty) + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%'
                      })}),_c('h5',{staticClass:"person-name"},[_c('i',{staticClass:"name-i"}),_vm._v(_vm._s(_vm.relaInheritorList[(i - 1) * 6 + (j - 1)].contentName)+" ")]),_c('p',{staticClass:"person-po"},[_vm._v("非遗项目："+_vm._s(_vm.relaInheritorList[(i - 1) * 6 + (j - 1)].extInfo))])]):_vm._e()]})],2)])}),1)],1)])]),_c('div',{staticClass:"city-contnt-one"},[_c('div',{staticClass:"city-con-width"},[_vm._m(4),_c('div',{staticClass:"person-item person-items"},[_c('el-carousel',{staticClass:"custon-carousel",attrs:{"interval":10000,"arrow":"never","height":"620px"}},_vm._l((Math.ceil(_vm.relaProjectList.length / 6)),function(i){return _c('el-carousel-item',{key:i},[_c('ul',{staticClass:"imgList"},[_vm._l((6),function(j){return [(_vm.relaProjectList[(i - 1) * 6 + (j - 1)])?_c('li',{key:j,style:({
                      backgroundImage: 'url(' + (_vm.relaProjectList[(i - 1) * 6 + (j - 1)].contentImg || _vm.empty) + ')',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: '100% 100%'
                    }),on:{"click":function($event){_vm.toContent(_vm.relaProjectList[(i - 1) * 6 + (j - 1)])}}},[_c('div',{staticClass:"fu-div"},[_vm._v(" "+_vm._s(_vm.relaProjectList[(i - 1) * 6 + (j - 1)].contentName)+" ")])]):_vm._e()]})],2)])}),1)],1)])]),_c('botto-com',{staticClass:"bottom-white no-fixed"}),_c('pop-video',{ref:"popVideo",attrs:{"video":_vm.projectVideo}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custon-line"},[_c('span',{staticClass:"line-tit"},[_vm._v(" 项目"),_c('i',{staticClass:"tit-red"},[_vm._v("介绍")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custon-line"},[_c('span',{staticClass:"line-tit"},[_vm._v(" 项目"),_c('i',{staticClass:"tit-red"},[_vm._v("资讯")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custon-line"},[_c('span',{staticClass:"line-tit"},[_vm._v(" 项目"),_c('i',{staticClass:"tit-red"},[_vm._v("传承人")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custon-line"},[_c('span',{staticClass:"line-tit"},[_vm._v(" 推荐"),_c('i',{staticClass:"tit-red"},[_vm._v("传承人")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custon-line"},[_c('span',{staticClass:"line-tit"},[_vm._v(" 推荐"),_c('i',{staticClass:"tit-red"},[_vm._v("项目")])])])}]

export { render, staticRenderFns }